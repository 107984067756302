@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat:wght@300;400;700&display=swap');

:root {
  --main-color: #123466;
  --secondary-color: #FFFFFF;
}

body, main, section, div, h1, h2, h3, h4, h5, span, a, li, ul, p, img, svg {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.hero-section {
  align-items: center;
  background: url('../public/hero-bg.png');
  background-position: center;
  background-position-y: top;
  background-position-x: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100%;
} 

.hero-section > img {
  width: 40rem;
}

.hero-date-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.hero-decoration-bar {
  background: var(--secondary-color);
  height: 3px;
  width: 12rem;
}

.hero-date-info > span {
  color: var(--secondary-color);
  font-size: 1rem;
  font-weight: bold;
  margin: 0 2rem;
  text-align: center;
}

.hero-section > p {
  color: var(--secondary-color);
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 0.9rem;
  text-align: center;
}

.counter-section {
  align-items: center;
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
}

.counter-section > span {
  color: var(--main-color);
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.counter-main-info {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  padding: 0 0 1.5rem 0;
  width: 90%;
}

.counter-main-info > h3{
  color: var(--main-color);
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.counter-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  justify-content: center;
}

.counter-item > h4 {
  color: var(--main-color);
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.counter-item > p {
  color: var(--main-color);
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
}

.poem-section-main {
  align-items: center;
  background: var(--main-color);
  color: var(--secondary-color);;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em 2rem 3rem 2rem;
}

.poem-section-main > h2 {
  font-family: 'Great Vibes', cursive;
  font-size: 2rem;
}

.poem-main-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 2rem 0 1rem 0;
  justify-content: center;
  width: 220px;
}

.poem-main-container  > p {
  font-size: 0.9rem;
  width: 100%;
}

.poem-main-container > span {
  align-self: end;
  font-size: 0.6rem;
  margin-top: 0.5rem;
}

.poem-text {
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;
  width: 80%;
}

.information-section {
  align-items: center;
  background: url('../public/information-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  justify-content: center;
  width: 100%;
}

.information-items-container {
  align-items: center;
  background: var(--secondary-color);
  display: grid;
  grid-column-gap: 1rem;
  grid-template: auto / repeat(2, 1fr);
  justify-content: center;
  width: 100%;
}

.information-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0;
  width: 100%;
}

.information-item > img {
  height: 5rem;
}

.information-item > h2 {
  color: var(--main-color);
  font-family: 'Great Vibes', cursive;
  font-size: 1.5rem;
}

.information-item > hr {
  background: var(--main-color);
  border: none;
  height: 3px;
  width: 40%;
}

.information-item > p {
  font-size: 0.8rem;
  text-align: center;
  width: 80%;
}

.information-item > span {
  color: var(--main-color);
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem 0;
  text-align: center;
}

.information-adress {
  text-align: center;
  margin-bottom: 1rem;
} 

.information-btn {
  background: var(--secondary-color);
  border: 2px solid var(--main-color);
  cursor: pointer;
  padding: 6px 36px;
}

.information-btn > a {
  font-size: 0.8rem;
  color: var(--main-color);
  text-decoration: none;
}

.information-btn:hover,
.information-btn:active {
  background: #eaeaea;
}

.information-btn:hover > a,
.information-btn:active > a {
  color: #555555;
}

.timeline-section {
  align-items: center;
  background: var(--secondary-color);
  display: flex;
  height: 55vh;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 2rem 0;
}

.timeline-section > h2 {
  color: var(--main-color);
  font-family: 'Good Vibes', cursive;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.timeline-items-container {
  align-items: center;
  display: grid;
  min-height: 45vh;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  width: 95%;
}

.timeline-item {
  align-items: center;
  border-left: 1px solid var(--main-color);
  border-right: 1px solid var(--main-color);
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.timeline-item:first-child {
  border-left: none;
}

.timeline-item:last-child {
  border-right: none;
}

.timeline-item > img {
  height: 3rem;
}

.timeline-item > h3 {
  color: var(--main-color);
  font-size: 1rem;
  text-align: center;
}

.timeline-item > p {
  font-size: 0.8rem;
  text-align: center;
  padding: 0 0.3rem;
}

.hotels-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 2rem 0;
}

.hotels-section > h2 {
  color: var(--main-color);
  font-family: 'Good Vibes', cursive;
  font-size: 2rem;
}

.hotels-items-container {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
}

.hotels-items-container > img {
  width: 80%;
}

.hotels-item {
  align-items: center;
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 80%;
}

.hotels-item > h3 {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.hotels-item > p {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.playlist-section {
  background: var(--main-color);
  height: 0.2rem;
  width: 100%;
}

.dresscode-section {
  align-items: center;
  background: url('../public/dress-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 3rem 0;
}

.dresscode-section > h2 {
  color: var(--secondary-color);
  font-family: 'Good Vibes', cursive;
  font-size: 2rem;
}

.dresscode-items-container {
  align-items: center;
  background: var(--secondary-color);
  border-radius: 2rem;
  display: flex;
  justify-content: space-around;
  margin: 2rem 0;
  padding: 2rem 3rem ;
  width: 80%;
}

.dresscode-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dresscode-item > img {
  height: 10rem;
}

.dresscode-item > span {
  color: var(--main-color);
  font-size: 1rem;
  font-weight: bold;
}

.dresscode-text-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.dresscode-text-container > span {
  color: var(--secondary-color);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 1rem;
}

.dresscode-decorator {
  background: var(--secondary-color);
  height: 3px;
  width: 10rem;
}

.gifttable-section {
  align-items: center;
  background: var(--secondary-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 4rem 0;
  width: 100%;
}

.gifttable-section  > h2 {
  color: var(--main-color);
  font-size: 2rem;
  font-family: 'Good Vibes', cursive;
  margin-bottom: 2rem;
}

.gifttable-card {
  align-items: center;
  border: 2px solid var(--main-color);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 1rem 0;
  width: 20rem;
}

.gifttable-card > img {
  width: 70%;
}

.gifttable-card > span {
  color: var(--main-color);
  font-weight: bold;
  font-size: 1rem;
  margin: 0.3rem 0;
}

.considerations-section {
  align-items: center;
  background: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0 2rem 0;
  text-align: center;
}

.considerations-section > h2 {
  color: var(--secondary-color);
  font-family: 'Good Vibes', cursive;
  font-size: 2rem;
}

.considerations-section > p {
  color: var(--secondary-color);
  font-size: 1rem;
  margin: 2rem 0;
}

.considerations-text {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 80%;
} 

.considerations-text > span {
  color: var(--secondary-color);
  margin: 0 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.considetations-decorator {
  background: var(--secondary-color);
  height: 2px;
  width: 30%;
}

.considerations-section > p:last-child {
  margin-top: 4rem;
  width: 50%;
}

.collage-section {
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
}

.collage-photo {
  border: 2px solid var(--main-color);
  height: 30vh;
}

.photo-1 {
  background: url('../public/couple-1.jpg');
  background-position: start;
  background-size: cover;
  background-repeat: no-repeat;
}

.photo-2 {
  background: url('../public/couple-2.jpeg');
  background-position-y: -100px;
  background-size: cover;
  background-repeat: no-repeat;
}

.photo-3 {
  background: url('../public/couple-11.jpeg');
  background-position-y: -200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.photo-4 {
  background: url('../public/couple-5.jpeg');
  background-position: start;
  background-size: cover;
  background-repeat: no-repeat;
}

.photo-5 {
  background: url('../public/couple-10.jpeg');
  background-position: start;
  background-size: cover;
  background-repeat: no-repeat;
}

.photo-6 {
  background: url('../public/couple-8.jpeg');
  background-position: start;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer-section {
  align-items: center;
  background: var(--main-color);
  display: flex;
  height: 20vh;
  justify-content: center;
  width: 100%;
}

.footer-section > img {
  height: 70%;
}

@media (min-width: 1100px) {
  .collage-photo {
    border: 2px solid var(--main-color);
    height: 40vh;
  }
}

@media (max-width: 900px) {
  .hero-section > img {
    width: 30rem;
  }
  .hero-date-info > span {
    font-size: 0.8rem;
  }
  .hero-section > p {
    font-size: 1rem;
  }
  .hero-decoration-bar {
    width: 8rem;
  }
  .counter-main-info {
    margin-top: 0.6rem;
    padding: 0 0 0.8rem 0;
  }
  .counter-section {
    padding: 0.5rem 0;
  }
  .counter-section > span {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
  .counter-item {
    margin: 0 0.7rem;
  }
  .counter-item > h4 {
    font-size: 1.6rem;
  }
  .counter-item > p {
    font-size: 0.8rem;
  }
  .timeline-items-container {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 1rem;
  }
  .timeline-item:nth-child(5) {
    border-left: none;
  }
  .considerations-text > span {
    font-size: 1rem;
  }
  .considerations-section > p:last-child {
    width: 70%;
  }
  .footer-section > img {
    height: 60%;
  }
}

@media (max-width: 600px) {
  .hero-section {
    background: url('../public/hero-bg-mobile.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .hero-section > img {
    width: 20rem;
  }
  .hero-date-info > span {
    font-size: 0.8rem;
  }
  .hero-section > p {
    font-size: 0.8rem;
  } 
  .hero-decoration-bar {
    height: 2px;
    width: 6rem;
  }
  .poem-main-container {
    width: 80%;
  }
  .poem-text {
    width: 90%;
  }
  .information-items-container {
    align-items: center; 
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .timeline-section {
    height: 75vh;
  }
  .timeline-items-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .timeline-item:nth-child(1) {
    border-right: 2px solid var(--main-color);
  }
  .timeline-item:nth-child(3) {
    border-right: none;
  }
  .timeline-item:nth-child(4) {
    border-left: none;
    border-right: 2px solid var(--main-color);
  }
  .timeline-item:nth-child(5) {
    border-right: 2px solid var(--main-color);
  }
  .hotels-items-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .hotels-items-container > img {
    width: 50%;
  }
  .hotels-item {
    border-top: 2px solid var(--main-color);
    margin: 0.5rem 0;
    padding: 0.5rem 0 0 0;
  }
  .dresscode-items-container {
    flex-direction: column;
    margin: 2rem 0;
    padding: 3rem 4rem ;
    width: auto;
  }
  .dresscode-item:first-child {
    margin-bottom: 3rem;
  }
  .dresscode-text-container {
    width: 100%;
  }
  .dresscode-decorator {
    width: 30%;
  }
  .gifttable-card {
    width: 90%;
  }
  .considerations-section > p {
    font-size: 0.7rem;
  }
  .considerations-text > span {
    font-size: 1rem;
  }
  .considerations-section > p:last-child {
    width: 90%;
  }
  .collage-section {
    grid-template-columns: repeat(2, 1fr);
  }
  .collage-photo {
    height: 20vh;
  }
  .photo-2 {
    background-position-y: -50px;
  }
  .photo-3 {
    background-position-y: center;
  }
  .photo-6 {
    background-position-y: center;
  }
  .footer-section > img {
    height: 50%;
  }
}

@media (max-width: 400px) {
  .hero-section > img {
    width: 15rem;
  }
  .hero-date-info > span {
    margin: 0 0.8rem;
  }
  .hero-decoration-bar {
    height: 2px;
    width: 5rem;
  }
  .poem-main-container {
    width: 85%;
  }
  .dresscode-text-container > span {
    font-size: 1rem;
  }
  .timeline-section {
    min-height: 80vh;
    padding-bottom: 4rem;
  }
  .timeline-items-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .timeline-item:nth-child(2),
  .timeline-item:nth-child(4) {
    border-right: 0;
  }
  .timeline-item:nth-child(3) {
    border-left: 0;
    border-right: 2px solid var(--main-color);
  }
}